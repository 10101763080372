<template>
  <div>
    <div class="config-container">
      <p>配置转换器：星空 - K</p>
      <div class="config-item">
        <input type="file" id="config" name="config" @change="convertConfig">
      </div>
    </div>
  </div>
</template>

<script>
const ExcelJS = require('exceljs');

export default {
  name: 'SpaceK',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  created() {},
  methods: {
    // Excel 转 JSON
    convertConfig(event) {
      const file = event.target.files[0];
      if (file.name.search(/.xlsx$/) < 0 && file.name.search(/.xls$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then((excel) => {
          let result = '{';

          // 配置项转化
          const configSheet = excel.worksheets[0];
          configSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"config": [';
              return;
            }

            let i = 2;
            result += `{
"value": "${this.getText(row.values[i++])}",
"key": "${this.getText(row.values[i++])}"
}`;
            if (configSheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += '],';
          });

          // 武器转化
          const weaponSheet = excel.worksheets[1];
          weaponSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"weapon": [';
              return;
            }

            let i = 1;
            const idI = this.getText(row.values[i++]);
            const nameI = this.getText(row.values[i++]);
            const iconPathI = this.getText(row.values[i++]);
            let levelI = this.getText(row.values[i++]);
            let volumeI = this.getText(row.values[i++]);
            let typeI = this.getText(row.values[i++]);
            const rangeI = this.getText(row.values[i++]);
            const attackI = this.getText(row.values[i++]);
            const frequencyI = this.getText(row.values[i++]);
            const cdI = this.getText(row.values[i++]);
            const ammoI = this.getText(row.values[i++]);
            const rpmI = this.getText(row.values[i++]);
            const hpI = this.getText(row.values[i++]);
            const weightI = this.getText(row.values[i++]);
            if (levelI === '常规') {
              levelI = 1;
            } else if (volumeI === '重型') {
              levelI = 2;
            }
            if (volumeI === '小') {
              volumeI = 1;
            } else if (volumeI === '中') {
              volumeI = 2;
            } else if (volumeI === '大') {
              volumeI = 3;
            }
            if (typeI === '火炮') {
              typeI = 1;
            } else if (typeI === '导弹') {
              typeI = 2;
            } else if (typeI === '激光') {
              typeI = 3;
            }
            result += `{
"id": ${idI},
"name": "${nameI}",
"iconPath": "${iconPathI}",
"level": ${levelI},
"volume": ${volumeI},
"type": ${typeI},
"range": ${rangeI},
"attack": ${attackI},
"frequency": ${frequencyI},
"cd": ${cdI},
"ammo": ${ammoI},
"rpm": ${rpmI},
"hp": ${hpI},
"weight": ${weightI}
}`;
            if (weaponSheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += '],';
          });

          // 引擎转化
          const engineSheet = excel.worksheets[2];
          engineSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"engine": [';
              return;
            }

            let i = 1;
            const idI = this.getText(row.values[i++]);
            const nameI = this.getText(row.values[i++]);
            const iconPathI = this.getText(row.values[i++]);
            let volumeI = this.getText(row.values[i++]);
            const speedI = this.getText(row.values[i++]);
            const rpmI = this.getText(row.values[i++]);
            const hpI = this.getText(row.values[i++]);
            const weightI = this.getText(row.values[i++]);
            if (volumeI === '小') {
              volumeI = 1;
            } else if (volumeI === '中') {
              volumeI = 2;
            } else if (volumeI === '大') {
              volumeI = 3;
            }
            result += `{
"id": ${idI},
"name": "${nameI}",
"iconPath": "${iconPathI}",
"volume": ${volumeI},
"speed": ${speedI},
"rpm": ${rpmI},
"hp": ${hpI},
"weight": ${weightI}
}`;
            if (engineSheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += ']';
          });

          result += '\n}';
          console.info(result);

          // 创建隐藏的可下载链接
          const eleLink = document.createElement('a');
          eleLink.download = 'config.json';
          eleLink.style.display = 'none';
          // 字符内容转变成blob地址
          const blob = new Blob([result]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击并移除
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
        });
      };
      reader.readAsArrayBuffer(file);
      return true;
    },
    // 获取实际 Text
    getText(cell) {
      if (cell === undefined) {
        return '';
      }
      let text = '';
      if (cell.richText) {
        cell.richText.map((value) => {
          if (value.text === '\n' || value.text === '\r' || value.text === '\r\n') {
            text += '\\n';
          } else {
            text += value.text.replaceAll('\n', '\\n');
          }
          return value;
        });
        return text;
      }
      if (typeof cell === 'string') {
        return cell.replaceAll('\n', '\\n');
      }
      return cell;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-container {
  display: flex;
  justify-content: flex-start;
}
.config-item {
  flex: 0 1 20%;
  a {
    color: cornflowerblue;
    text-decoration: underline;
  }
  a:hover {
    color: cornflowerblue;
    text-decoration: none;
  }
}

.board-container {
  width: 470px;
  height: 470px; /* height given for illustration */
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.board-item {
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid black;
  margin: 4px;
  flex: 0 1 calc(20% - 10px);
}

a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

/* 模态框 */
.overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: relative;
    width: 600px;
    max-width: 80%;
    background: white;
    border-radius: 8px;
    padding: 1em 2em;
    .modal-close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: grey;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
.overlay:target {
  visibility: visible;
}
</style>
